<template>
  <div class="menu-facture">
    <!-- MENU TABULATION FACTURE  -->
    <div class="list-tabs">
      <v-tabs
        :hide-slider="false"
        slider-color="#704ad1"
        color="#704ad1"
        item-color="#704ad1"
        v-model="tabValue"
      >
        <template v-for="(item, index) in itemsFactures">
          <v-tab
            :key="index"
            :href="`#tab-${index}`"
            color="#704ad1"
            item-color="#704ad1"
            v-if="computedCoesCheck(item.code)"
          >
            <router-link :to="item.href" class="font-tabs-facture">
              {{ item.name }}
            </router-link>
          </v-tab></template
        >
      </v-tabs>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    itemsFactures: { required: true },
    model: {
      required: false
    }
  },
  data() {
    return { tabValue: this.model };
  },
  watch: {
    $route(e) {
      if (
        e.name == 'facture-libre' ||
        e.name == 'facture-sci' ||
        e.name == 'simulationFactureFrais' ||
        e.name == 'gestion-facture-mensuel' ||
        e.name == 'masterFiliale-2' ||
        e.name == 'appel-paiement' ||
        e.name == 'visionGlobaleFrais' ||
        e.hash == '#'
      ) {
        if (
          e.name == 'simulationFactureFrais' ||
          e.name == 'visionGlobaleFrais'
        ) {
          this.tabValue = 'tab-0';
        } else {
          this.tabValue = e.meta.tab;
        }
      }
    }
  },
  computed: {
    ...mapGetters(['computedRouterLinkFacture', 'checkPermission']),
    computedCoesCheck() {
      return function(coeds) {
        if (typeof coeds == 'string') {
          return this.checkPermission(coeds);
        } else {
          let data = false;
          coeds.forEach(element => {
            if (this.checkPermission(element)) {
              data = true;
            }
          });
          return data;
        }
      };
    }
  },
  mounted() {
    if (this.computedRouterLinkFacture) {
      if (
        this.$route.name == 'simulationFactureFrais' ||
        this.$route.name == 'visionGlobaleFrais'
      ) {
        this.tabValue = 'tab-0';
      } else {
        this.tabValue = this.$route.meta.tab;
      }
    }
  }
};
</script>
<style scoped lang="scss">
.menu-facture {
  position: absolute;
  top: 52px;
  right: 0;
  left: 0px;
  height: 30px;
  z-index: 999;
  background: #fff;
  border-top: outset;
  .list-tabs {
    background-color: #f6f6f6;
    display: flex;
    align-items: center;
    .v-tabs {
      width: max-content;
    }
    .v-application .deep-purple {
      background-color: #704ad1 !important;
      border-color: #704ad1 !important;
      width: 100%;
    }
  }
}
.v-tab {
  // max-width: 152px;
  // margin-right:2px ;
}
.v-application {
  a {
    color: #2f2f2f !important;
    font-size: 11px;
    font-weight: 600;
    padding: 4px 5px;
    font-family: 'Montserrat', sans-serif;
    white-space: nowrap;
    // line-height: 42px;
    // padding: 9px 24px 9px 23px;
    text-align: center;
    text-transform: capitalize;
    &.router-link-exact-active,
    &.v-tab--active {
      color: #704ad1 !important;
      font-size: 11px;
      font-weight: 600;
      font-family: 'Montserrat', sans-serif;
      white-space: nowrap;
      a {
        color: #704ad1 !important;
        font-size: 11px;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
        white-space: nowrap;
      }
      // line-height: 42px;
      &:hover {
        background-color: transparent;
      }
    }
    &:hover {
      text-decoration: none;
      background-color: #f1edfb;
    }
    &:focus {
    }
  }
}
.v-application--is-ltr
  .v-tabs--align-with-title
  > .v-tabs-bar:not(.v-tabs-bar--show-arrows):not(.v-slide-group--is-overflowing)
  > .v-slide-group__wrapper
  > .v-tabs-bar__content
  > .v-tab:first-child,
.v-application--is-ltr
  .v-tabs--align-with-title
  > .v-tabs-bar:not(.v-tabs-bar--show-arrows):not(.v-slide-group--is-overflowing)
  > .v-slide-group__wrapper
  > .v-tabs-bar__content
  > .v-tabs-slider-wrapper
  + .v-tab {
  margin-left: 0px;
  padding: 0px;
}
</style>
<!-- <style lang="scss">
.v-tabs-bar {
  border-radius: inherit;
  height: 30px;
}
</style> -->
